import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from 'react';
import Column from '../components/Layout/Column';
import Row from '../components/Layout/Row';
import DigimarcLogo from "../components/DigimarcLogo";
import { login } from "../services/api";
import { useSnackbar } from "notistack";
import { notistackOptions } from "../utils/notistackUtils";
import { setUser } from '../store/action';
import { useDispatch } from "react-redux";
import RegisterPage from "./RegisterPage";


const ConnectPage = () => {

  const [email, setEmail] = useState('');
  const [password, setPwd] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onLoginClicked = async () => {
    try {
      const res = await login(email, password);
      if (!res.email) {
        throw new Error('User not found');
      }
      enqueueSnackbar('Successfully connected', notistackOptions.SUCCESS);
      dispatch(setUser(res));
    } catch (e) {
      enqueueSnackbar(e.toString(), notistackOptions.ERROR);
    }
  }

  const register = () => {
    setIsRegistering(true);
  }

  if (isRegistering)
    return <RegisterPage goBack={() => window.location.reload()}/>

  return (
    <Column width='100vw' height='100vh' justifyContent='center' alignItems='center'>
      <Row justifyContent='end' width='90vw'>
        <Typography variant='button' style={{ textTransform: 'none', marginTop: '24px', cursor: 'pointer' }} onClick={register}>
          {"Register"}
        </Typography>
      </Row>
      <Column width='30%' height='100%' justifyContent='center' alignItems='center' mt='-40px'>
        <DigimarcLogo />
        <TextField fullWidth id="email" label="Email" variant="outlined" value={email} style={{ marginTop: '32px' }} onChange={(e) => setEmail(e.target.value)} />
        <TextField fullWidth id="password" label="Password" variant="outlined" value={password} style={{ marginTop: '18px' }} onChange={(e) => setPwd(e.target.value)} type="password" />
        <Button onClick={onLoginClicked} variant="contained" component="label" disableElevation style={{ width: '100%', marginTop: '18px' }}>
          <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
            {"Connect"}
          </Typography>
        </Button>
      </Column>
    </Column>
  );
};

export default ConnectPage;
