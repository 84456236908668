import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { Button, CircularProgress, Typography } from "@mui/material";
import Column from "../../Layout/Column";
import store from "../../../store/store";
import Row from '../../Layout/Row';
import JSZip from 'jszip';
import { getWatermarkedImages } from '../../../services/api';
import { useSnackbar } from "notistack";
import { notistackOptions } from '../../../utils/notistackUtils';
import { setUrl } from '../../../store/action';
import { createWatermarkService, getWatermarkServiceInfo } from '../../../services/servicesApi';

const RightPanelDownload = ({onError}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState(true);
  const {selectedProduct, url, selectedBrand, user} = store.getState();
  const { enqueueSnackbar } = useSnackbar();

  const fetchImages = async () => {
    setIsLoading(true);
    const { ServiceId } = await createWatermarkService(selectedProduct, selectedBrand, user.id);
    const { Payload, CpmPath } = await getWatermarkServiceInfo(ServiceId);
    console.log(CpmPath)
    const res = await getWatermarkedImages(url, selectedProduct, selectedBrand, user.id, ServiceId, Payload, CpmPath);
    if (res.message) {
      enqueueSnackbar(res.message, notistackOptions.ERROR);
      store.dispatch(setUrl(''));
      onError();
    } else {
      setImages(res);
    }
    setIsLoading(false);
  }

  const downloadImages = async () => {
    const zip = new JSZip()

  for (let i = 0; i < images.length; i++) {
    const base64Image = images[i]
    const blob = await fetch(`data:image/jpeg;base64,${base64Image}`).then(res => res.blob())
    const filename = `image-${i}.png`
    zip.file(filename, blob)
  }

  zip.generateAsync({ type: 'blob' }).then(blob => {
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = 'images.zip'
    link.href = url
    link.click()
  })
  }

  useEffect(() => {
    fetchImages();
  }, []);

  if (isLoading)
    return (
      <Column width='100vw' height='100%' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Column>
    );

  return (
    <Column width='100%' alignItems='center'>
      <Typography variant='h1'>Download images</Typography>
      <div style={{ marginTop: '48px' }} />
      <Row width='100%' alignItems='center' justifyContent='center'>
        {images.map((image, i) => {
          return <img src={`data:image/jpeg;base64,${image}`} alt={`image ${i}`} width={"18%"} />
        })}
      </Row>
      <div style={{ marginTop: '48px' }} />
      <Button onClick={downloadImages} variant="contained" component="label" disableElevation style={{ width: '25%' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Download"}
        </Typography>
      </Button>
    </Column>
  );
};

export default withTheme(RightPanelDownload);
