import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import HelpOverlay from "./components/HelpOverlay";
import { getQueryParam, load } from "./utils/utils";
import SecureListingPage from "./pages/Reseller/SecureListingPage";
import { useAccount } from 'wagmi';
import ConnectPage from './pages/ConnectPage';
import { getUsers } from './services/api';
import AddProductPage from './pages/Brands/AddProductPage';
import { CircularProgress, Typography } from '@mui/material';
import Column from './components/Layout/Column';
import Row from './components/Layout/Row';
import AddResellerPage from './pages/Brands/AddResellerPage';
import store from './store/store';
import { setBrands, setResellers, setUser } from './store/action';
import { useSelector } from 'react-redux';

const App = () => {

  const [showOverlay, setShowOverlay] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [users, setUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const user = useSelector(state => state.user);

  const isConnected = user?.email ? true : false;

  const loadUsers = async () => {
    setIsLoading(true);
    const _users = await getUsers();
    store.dispatch(setResellers(_users.resellers));
    store.dispatch(setBrands(_users.brands));
    setUsers(_users);
    setIsLoading(false);
  }

  const checkUserType = () => {
    if (!users || !user)
      return;

    const brand = users.brands.find((brand) => brand.email === user.email);
    const reseller = users.resellers.find((reseller) => reseller.email === user.email);

    if (brand) {
      setIsBrand(true);
      setIsReseller(false);
      setUserInfo(brand);
    }
    else if (reseller) {
      setIsReseller(true);
      setIsBrand(false);
      setUserInfo(reseller);
    } else {
      setIsReseller(false);
      setIsBrand(false);
    }
  }

  useEffect(() => {
    const help = getQueryParam('help');
    if (help)
      setShowOverlay(true);
  }, []);

  useEffect(() => {
    const user = load('user');
    if (user)
      store.dispatch(setUser(JSON.parse(user)));
    loadUsers();
  }, []);

  useEffect(() => {
    checkUserType();
  }, [user, users]);

  if (isLoading)
    return (
      <Column width='100vw' height='100vh' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Column>
    );

  return (
    <div>
      <HelpOverlay show={showOverlay} setShow={setShowOverlay} />
      {!isConnected &&
        <ConnectPage />
      }
      {isConnected && isReseller &&
        <SecureListingPage setShowOverlay={setShowOverlay} />
      }
      {isConnected && isBrand &&
        <Routes>
          <Route path="/" element={<AddProductPage setShowOverlay={setShowOverlay} />} />
          <Route path="/resellers" element={<AddResellerPage setShowOverlay={setShowOverlay} />} />
        </Routes>
      }
    </div>
  );
};

export default App;
