import { Typography, Button, TextField, Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import React, { useState } from 'react';
import Column from '../components/Layout/Column';
import Row from '../components/Layout/Row';
import DigimarcLogo from "../components/DigimarcLogo";
import { login, registerUser } from "../services/api";
import { useSnackbar } from "notistack";
import { notistackOptions } from "../utils/notistackUtils";
import { setUser } from '../store/action';
import { useDispatch } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RegisterPage = ({goBack}) => {

  const [email, setEmail] = useState('');
  const [password, setPwd] = useState('');
  const [image, setImage] = useState('');
  const [type, setType] = useState('brand');
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onRegisterClicked = async () => {
    try {
      const res = await registerUser(name, image, email, password, type);
      if (!res)
        throw new Error('An error occured');
      enqueueSnackbar('Successfully connected', notistackOptions.SUCCESS);
      window.location.reload();
    } catch (e) {
      enqueueSnackbar(e.toString(), notistackOptions.ERROR);
    }
  }

  return (
    <Column width='100vw' height='100vh' justifyContent='center' alignItems='center'>
      <Row justifyContent='start' width='100vw'>
        <ArrowBackIcon style={{marginLeft: '24px', marginTop: '24px', cursor: 'pointer'}} onClick={goBack}/>
      </Row>
      <Column width='30%' height='100%' justifyContent='center' alignItems='center' mt='-40px'>
        <DigimarcLogo />
        <TextField fullWidth id="email" label="Email" variant="outlined" value={email} style={{ marginTop: '18px' }} onChange={(e) => setEmail(e.target.value)} />
        <TextField fullWidth id="password" label="Password" variant="outlined" value={password} style={{ marginTop: '18px' }} onChange={(e) => setPwd(e.target.value)} type="password" />
        <TextField fullWidth id="name" label="Name" variant="outlined" value={name} style={{ marginTop: '18px' }} onChange={(e) => setName(e.target.value)} />
        <TextField fullWidth id="image" label="Image" variant="outlined" value={image} style={{ marginTop: '18px' }} onChange={(e) => setImage(e.target.value)} />
        <FormControl fullWidth style={{ marginTop: '24px' }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="type"
            onChange={(e) => setType(e.target.value)}
          >
             <MenuItem value={'brand'}>brand</MenuItem>
             <MenuItem value={'reseller'}>reseller</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={onRegisterClicked} variant="contained" component="label" disableElevation style={{ width: '100%', marginTop: '18px' }}>
          <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
            {"Create"}
          </Typography>
        </Button>
      </Column>
    </Column>
  );
};

export default RegisterPage;
