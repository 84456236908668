import React, { useState } from 'react';
import { withTheme } from '@emotion/react';
import { Typography, TextField, Button } from "@mui/material";
import Column from "../../Layout/Column";
import Row from "../../Layout/Row";
import { useSnackbar } from "notistack";
import { notistackOptions } from "../../../utils/notistackUtils";
import { isUrl } from '../../../utils/utils';
import { useAccount } from 'wagmi';
import { addProduct } from '../../../services/api';
import RightPanelProductView from './RightPanelProductView';
import { useSelector } from 'react-redux';

const ProductView = ({ product }) => {
  return <RightPanelProductView productId={product.id}/>
}

const RightPanelAddProduct = ({ onProductAdded, theme, selectedProduct }) => {
  const [name, setName] = useState("");
  const [images, setImages] = useState([""]);
  const [triggerRender, setTriggerRender] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(state => state.user);
  const primaryColor = theme.palette.primary.main;

  const onAddProductClick = async () => {
    const areAllUrls = images.filter(i => !isUrl(i));

    if (areAllUrls.length) {
      enqueueSnackbar('Please provide valid URLs', notistackOptions.ERROR);
      return;
    }

    if (!name) {
      enqueueSnackbar('Please provide a valid name', notistackOptions.ERROR);
      return;
    }

    const res = await addProduct(name, images, user.id);
    if (!res.id) {
      enqueueSnackbar("An error occurred while adding the product", notistackOptions.ERROR);
      return;
    }
    enqueueSnackbar("Product successfully added", notistackOptions.SUCCESS);
    setName('');
    setImages([]);
    onProductAdded(res);
  }

  const addImageClick = () => {
    const flag = images.filter(i => i.length === 0);
    if (flag.length) {
      enqueueSnackbar('Please fill all the empty fields first', notistackOptions.ERROR);
      return;
    }

    images.push('');
    setImages(images);
    setTriggerRender(!triggerRender);
  }

  if (selectedProduct)
    return <ProductView product={selectedProduct} />

  return (
    <Column width='50%' alignItems='center' style={{ maxWidth: '500px' }}>
      <Typography variant='h1'>Add a product</Typography>
      <TextField fullWidth id="name" label="Product name" variant="outlined" value={name} style={{ marginTop: '24px' }} onChange={(e) => setName(e.target.value)} />
      {
        images.map((imageUrl, i) => <TextField
          fullWidth
          id={imageUrl}
          label={`Image URL`}
          variant="outlined"
          value={imageUrl}
          style={{ marginTop: '24px' }}
          onChange={(e) => {
            images[i] = e.target.value;
            setImages(images);
            setTriggerRender(!triggerRender);
          }}
        />)
      }
      <Row width='100%' mt='24px'>
        <Button onClick={addImageClick} variant="outlined" component="label" disableElevation style={{ width: '100%' }}>
          <Typography variant='button' style={{ textTransform: 'none', color: primaryColor }}>
            {"Add an image"}
          </Typography>
        </Button>
        <div style={{ marginLeft: '12px' }} />
        <Button onClick={onAddProductClick} variant="contained" component="label" disableElevation style={{ width: '100%' }}>
          <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
            {"Submit"}
          </Typography>
        </Button>
      </Row>
    </Column>
  );
};

export default withTheme(RightPanelAddProduct);
