import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import Column from "../Layout/Column";
import DigimarcLogo from "../DigimarcLogo";
import { Button, Typography } from "@mui/material";

const LeftPanel = ({ theme, setShowOverlay, children, bottomComponent = <div/>}) => {

  const { palette } = theme;
  const secondaryColor = palette.background.secondary;

  return (
    <Column width="30vw" minHeight='100vh'>
      <Column style={{
        marginLeft: '12px',
        marginTop: '12px',
        marginBottom: '12px',
        borderRadius: '18px',
        height: '100%',
        padding: '24px',
        minHeight: '90vh',
        backgroundColor: secondaryColor
      }} justifyContent='space-between'>
        <Column>
          <DigimarcLogo />
          <div style={{ marginTop: '50px' }} />
          {children}
        </Column>
        <Column>
          {bottomComponent}
          <Button variant="contained" color={"button"} disableElevation style={{
            border: '1px solid lightgrey'
          }} onClick={() => setShowOverlay(true)}>
            <Typography variant='button' style={{ textTransform: 'none' }}>How does it work?</Typography>
          </Button>
        </Column>
      </Column>
    </Column>
  );
};

export default withTheme(LeftPanel);
