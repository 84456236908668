import React, { useState } from 'react';
import { withTheme } from '@emotion/react';
import { Button, TextField, Typography } from "@mui/material";
import Column from "../../Layout/Column";
import store from "../../../store/store";
import { setUrl } from '../../../store/action';
import { useSnackbar } from "notistack";
import { notistackOptions } from "../../../utils/notistackUtils";

const RightPanelProvideURL = ({ setNextStep }) => {

  const [url, _setUrl] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const onButtonClick = () => {
    try {
      store.dispatch((setUrl(url)));
      setNextStep();
    } catch (e) {
      enqueueSnackbar(e.toString(), notistackOptions.ERROR);
    }
  }

  return (
    <Column width='50%' alignItems='center' style={{ maxWidth: '500px' }}>
      <Typography variant='h1'>Provide the product URL</Typography>
      <TextField fullWidth id="URL" label="Product URL" variant="outlined" value={url} style={{ marginTop: '18px' }} onChange={(e) => _setUrl(e.target.value)} />
      <Button onClick={onButtonClick} variant="contained" component="label" disableElevation style={{ width: '100%', marginTop: '18px' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Next"}
        </Typography>
      </Button>
    </Column>
  );
};

export default withTheme(RightPanelProvideURL);
