import store from "../store/store";

const API_URL = 'https://validate-4-ecomm-api-zyciyb2wfa-ue.a.run.app'

export const getUsers = async () => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/users`, requestOptions);
  return (await res.json());
}

export const getProductData = async (productId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/analytics?product=${productId}`, requestOptions);
  return (await res.json());
}

export const addProduct = async (name, images, brandId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const json = {
    name,
    images
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(json),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/brands/${brandId}/products`, requestOptions);
  return (await res.json());
}

export const registerUser = async (name, image, email, password, type) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const json = {
    name,
    image,
    email,
    password,
    type
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(json),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/register`, requestOptions);
  if (res.status === 200)
    return true;
  return false;
}

export const addReseller = async (brandId, resellerId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const json = {
    resellerId
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(json),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/brands/${brandId}/resellers`, requestOptions);
  return (await res.json());
}

export const deleteReseller = async (brandId, resellerId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/brands/${brandId}/resellers/${resellerId}`, requestOptions);
  return res.status;
}

/**
 * 
 * @param {String} brandAddress - the address of the brand
 * @returns the list of products associated with the brand
 */
export const getProducts = async (brandId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/brands/${brandId}/products`, requestOptions);
  return (await res.json());
}

export const authorizeProductForResellers = async (resellers, productId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(resellers),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/products/${productId}/resellers`, requestOptions);
  return (await res.json());
}

/**
 * 
 * @param {String} brandAddress - the address of the brand
 * @returns the list of resellers associated with the brand
 */
export const getResellers = async (brandId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/brands/${brandId}/resellers`, requestOptions);
  return (await res.json());
}

/**
 * Get all the brands that whitelisted the reseller
 * @param {string} resellerAddress 
 * @returns the list of brands addresses
 */
export const getResellerAssociatedBrands = async (resellerId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/resellers/${resellerId}/brands`, requestOptions);
  return (await res.json());
}

export const getSecuredUrls = async (productId) => {
  const myHeaders = new Headers();

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    body: null,
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/products/${productId}/protectedURLs`, requestOptions);
  return (await res.json());
}

export const getSecuredUrlsForReseller = async (resellerId, brandId) => {

  // this could be optimized, but here, we fetch URLs + products to be able to show what we want to show

  const getURLs = async () => {
    const myHeaders = new Headers();

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      body: null,
      redirect: 'follow'
    };

    const res = await fetch(`${API_URL}/resellers/${resellerId}/protectedURLs`, requestOptions);
    return (await res.json());
  }

  const promises = [];
  promises.push(getURLs());
  promises.push(getProducts(brandId));
  const res = await Promise.all(promises);
  return res[0].map(url => {
    const obj = {
      ...url,
      product: res[1].find(i => i.id === url.productId)
    };
    delete obj.productId;
    return obj;
  });
}

export const getWatermarkedImages = async (url, productId, brandId, resellerId, ServiceId, Payload, CpmPath) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      url: url,
      productId,
      brandId,
      resellerId,
      ServiceId,
      Payload,
      CpmPath
    }),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/secure`, requestOptions);
  return (await res.json());
}

export const login = async (email, pwd) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const json = {
    email,
    password: pwd
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(json),
    redirect: 'follow'
  };

  const res = await fetch(`${API_URL}/login`, requestOptions);

  if (res.status === 200)
    return (await res.json());
  throw new Error("Couldn't find user");
}