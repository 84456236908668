import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import Header from "../../components/Layout/Header";
import Row from "../../components/Layout/Row";
import Column from "../../components/Layout/Column";
import {
  setCurrentStep as _setCurrentStep,
} from "../../store/action";
import { useSnackbar } from "notistack";
import RightPanelAddProduct from '../../components/rightPanel/brands/RightPanelAddProduct';
import { getProducts } from '../../services/api';
import { useAccount } from 'wagmi';
import LeftPanelProduct from '../../components/leftPanel/brands/LeftPanelBrand';
import { notistackOptions } from '../../utils/notistackUtils';
import { useSelector } from 'react-redux';

const AddProductPage = ({ setShowOverlay }) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(state => state.user);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLeftPanelLoading, setIsLeftPanelLoading] = useState(true);
  const [triggerRender, setTriggerRender] = useState(false);

  const loadProducts = async () => {
    const _products = await getProducts(user.id);
    setIsLeftPanelLoading(false);
    setProducts(_products);
  }

  useEffect(() => {
    loadProducts();
  }, []);

  const onProductAdded = (product) => {
    setSelectedProduct(product);
    products.push(product);
    setProducts(products);
    setTriggerRender(!triggerRender);
  }

  return (
    <Row>
      <LeftPanelProduct
        isLeftPanelLoading={isLeftPanelLoading}
        items={products}
        setShowOverlay={setShowOverlay}
        onItemSelected={setSelectedProduct}
        onAddButtonClick={() => {
          if (selectedProduct)
            setSelectedProduct(null);
          else 
            enqueueSnackbar('You are already adding a product', notistackOptions.WARNING);
        }}
        type={"product"}
      />
      <Column>
        <Header />
        <Column height={'100%'} width={'70vw'} alignItems='center' justifyContent='center'>
          <RightPanelAddProduct onProductAdded={onProductAdded} selectedProduct={selectedProduct} />
        </Column>
      </Column>
    </Row>
  );
};

export default withTheme(AddProductPage);
