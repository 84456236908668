import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Column from "../../Layout/Column";
import store from "../../../store/store";
import { setSelectedProduct } from '../../../store/action';
import { useSelector } from 'react-redux';
import { getProducts } from '../../../services/api';
import { useAccount } from 'wagmi';

const RightPanelSelectProduct = ({ setNextStep }) => {

  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const user = useSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const selectedBrand = useSelector(state => state.selectedBrand);

  const loadProducts = async () => {
    setIsLoading(true);
    let _products = await getProducts(selectedBrand);
    _products = _products.filter(p => {
      let r = p.customFields.resellers;
      return r.includes(user.id);
    });
    console.log(_products);
    setProducts(_products);
    if (_products.length)
      setProduct(_products[0].id);
    setIsLoading(false);
  }

  const onButtonClick = () => {
    store.dispatch(setSelectedProduct(product));
    setNextStep();
  }

  useEffect(() => { loadProducts() }, []);

  if (isLoading)
    return (
      <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Column>
    )

  if (!products.length)
    return (
      <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Typography variant='h6' mr="50px" ml="50px">You don't have any whitelisted product for this brand</Typography>
      </Column>
    )

  return (
    <Column width='50%' alignItems='center' style={{ maxWidth: '500px' }}>
      <Typography variant='h1'>Select the product</Typography>
      <FormControl fullWidth style={{ marginTop: '24px' }}>
        <InputLabel id="demo-simple-select-label">Product</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={product}
          label="product"
          onChange={(e) => setProduct(e.target.value)}
        >
          {products.map((k) => <MenuItem value={k.id}>{k.name}</MenuItem>)}
        </Select>
      </FormControl>
      <Button onClick={onButtonClick} variant="contained" component="label" disableElevation style={{ width: '100%', marginTop: '18px' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Next"}
        </Typography>
      </Button>
    </Column>
  );
};

export default withTheme(RightPanelSelectProduct);
