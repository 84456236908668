import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { Tab, Tabs } from "@mui/material";
import Column from "../../Layout/Column";
import { RightPanelSecuredURLs } from './resellerView/RightPanelSecuredURLs';
import { RightPanelAuthorizations } from './resellerView/RightPanelAuthorizations';
import { getSecuredUrlsForReseller } from '../../../services/api';
import store from '../../../store/store';

const RightPanelResellerView = ({ reseller, onResellerRemoved }) => {
    const [tab, setTab] = useState(0);
    const [securedURLsLoading, setSecuredURLsLoading] = useState(true);
    const [urls, setUrls] = useState([]);

    const loadSecuredURLs = async () => {
        const { user } = store.getState();
        setSecuredURLsLoading(true);
        setUrls(await getSecuredUrlsForReseller(reseller.id, user.id));
        setSecuredURLsLoading(false);
    }

    useEffect(() => {
        loadSecuredURLs();
    }, [reseller]);

    const onTabChange = (event, newTab) => {
        setTab(newTab);
    }

    return (
        <Column height='100%' width='100%'>
            <div style={{ marginTop: '30px' }} />
            <Tabs value={tab} onChange={onTabChange} centered>
                <Tab label="Authorizations" />
                <Tab label="Secured URLs" />
            </Tabs>
            <div style={{ marginTop: '30px' }} />
            {tab === 0 && <RightPanelAuthorizations reseller={reseller} onResellerRemoved={onResellerRemoved} />}
            {tab === 1 && !securedURLsLoading && <RightPanelSecuredURLs urls={urls} />}
        </Column>
    )

};

export default withTheme(RightPanelResellerView);
