import {Backdrop, Button, Typography, useTheme} from "@mui/material";
import Column from "./Layout/Column";
import CloseIcon from "@mui/icons-material/Close";
import protect_icon from "../assets/protect_nft.png";
import Row from "./Layout/Row";
import React from "react";
import {withTheme} from "@emotion/react";

const Overlay = ({show, onCancel, onRestore, title, description, rightButtonText}) => {

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={show}
    >
      <Column
        style={{
          width: '40%',
          maxWidth: '500px',
          opacity: 1,
          zIndex: 1000,
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '24px'
        }}>
        <Column sx={{margin: '32px'}}>
          <Typography variant='overlayTitle' sx={{zIndex: 'inherit'}}>{title}</Typography>
          <span style={{height: '24px'}}/>
          <Typography variant='overlayDescription' sx={{zIndex: 'inherit', textAlign: 'justify'}}>{description}</Typography>
          <Row style={{marginTop: '24px'}}>
            <Button onClick={onCancel} variant="outlined" component="label" disableElevation style={{width: '100%'}}>
              <Typography variant='button' style={{textTransform: 'none', color: primaryColor}}>
                Cancel
              </Typography>
            </Button>
            <span style={{width: '24px'}}/>
            <Button onClick={onRestore} variant="contained" component="label" disableElevation
                    style={{width: '100%', height: '45px'}}>
              <Typography variant='button' style={{textTransform: 'none', color: 'white'}}>
                {rightButtonText}
              </Typography>
            </Button>
          </Row>
        </Column>
      </Column>
    </Backdrop>
  )
}

const OverlayWithTheme = withTheme(Overlay);

export const RestoreSessionOverlay = ({show, onRestore, onCancel}) => {

  return (
    <OverlayWithTheme show={show} onRestore={onRestore} onCancel={onCancel} rightButtonText='Restore'
             description='You left off protecting your NFT, do you want to continue where you left off?'
             title='Resume Your Protection'
    />
  );
}

export const OnBrowserBackHistoryClickOverlay = ({show, onCancel, onGoBack}) => {
  return (
    <OverlayWithTheme show={show} onRestore={onGoBack} onCancel={onCancel} rightButtonText='Yes'
             description="You are going to leave the application. Your progress won't be lost. However, if you just
             want to go the a previous step, you can just select it on the left panel."
             title='Leave the protect process?'/>
  );
}
