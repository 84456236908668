import { CircularProgress } from '@mui/material';
import React from 'react';
import Column from '../components/Layout/Column';
import Header from '../components/Layout/Header';

const LoadingPage = () => {
    return (
        <Column width='100vw' height='100vh' alignItems='end'>
            <Header showNavigationButtons={false}/>
            <Column width='100%' height='90%' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Column>
        </Column>
    );
};

export default LoadingPage;