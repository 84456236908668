import { combineReducers } from 'redux';

/**
 * Create a reducer with appropriate handlers.
 *
 * @param {*} initialState - Initial state of the reducer.
 * @param {object} handlers - Object of handlers for each action type.
 * @returns {Function} Reducer function.
 */
const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) =>
    // eslint-disable-next-line no-prototype-builtins
    handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;

export default combineReducers({
  // the screen of the size
  screenSize: createReducer(
    { width: undefined, height: undefined },
    {
      SET_SCREEN_SIZE: (state, { value }) => value,
    }
  ),
  // useful to know if it is a mobile screen or not
  isMobile: createReducer(null, {
    SET_IS_MOBILE: (state, { value }) => value,
  }),

  user: createReducer(null, {
    SET_USER: (state, { value }) => value,
  }),

  // reseller secures a url
  selectedBrand: createReducer('', {
    SET_SELECTED_BRAND: (state, { value }) => value,
  }),
  selectedProduct: createReducer('', {
    SET_SELECTED_PRODUCT: (state, { value }) => value,
  }),
  url: createReducer('', {
    SET_URL: (state, { value }) => value,
  }),
  currentStep: createReducer(1, {
    SET_CURRENT_STEP: (state, { value }) => value,
  }),

  resellers: createReducer([], {
    SET_RESELLERS: (state, { value }) => value,
  }),
  brands: createReducer([], {
    SET_BRANDS: (state, { value }) => value,
  }),
});
