import { ConnectKitButton } from 'connectkit';
import React from 'react';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Row from "./Row";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography, Button, IconButton, Box } from '@mui/material';
import {useTheme, withTheme} from '@emotion/react';
import store from '../../store/store';
import { setUser } from '../../store/action';

const PAGES = {
  resellers: {
    id: 1,
    location: '/resellers'
  },
  products: {
    id: 2,
    location: '/'
  }
}

const Header = ({ backButton, onBack, width = '70vw', showNavigationButtons = true }) => {

  const navigate = useNavigate();
  const userInfo = useSelector(state => state.user);
  const theme = useTheme();

  const primaryColor = theme.palette.primary.main;

  const getActualPage = () => {
    if (window.location.pathname.startsWith('/resellers'))
      return PAGES.resellers;

    return PAGES.products;
  }

  const goToPage = (page) => {
    if (getActualPage().id === page.id)
      return;

    navigate(page.location);
  }

  const disconnect = () => {
    store.dispatch(setUser(null));
  }

  return (
    <Row justifyContent='space-between' alignItems='center' width={width} mt="24px">
      {backButton && (
        <Box ml='24px' style={{ cursor: 'pointer' }} onClick={onBack}>
          <ArrowBackOutlinedIcon />
        </Box>
      )}
      {
        !backButton && <span />
      }
      <Row alignItems='center'>
        {showNavigationButtons &&
          <Typography
            variant={getActualPage().id === PAGES.resellers.id ? 'headerSelected' : 'header'} mr='48px'
            style={{ cursor: 'pointer' }}
            onClick={() => goToPage(PAGES.resellers)}
          >
            Resellers
          </Typography>
        }
        {
          showNavigationButtons &&
          <Typography
            variant={getActualPage().id === PAGES.products.id ? 'headerSelected' : 'header'} mr='48px'
            style={{ cursor: 'pointer' }}
            onClick={() => goToPage(PAGES.products)}
          >
            Products
          </Typography>
        }
        <Row sx={{marginRight: '24px'}}>
          <Button
            onClick={disconnect}
            variant="contained"
            style={{
              paddingRight: '24px',
              border: `1px solid ${primaryColor}`,
              backgroundColor: '#fff',
              borderRadius: '12px',
              textTransform: 'none',
            }}
          >
            {userInfo.image && (
              <img
                src={userInfo.image}
                alt={userInfo.name}
                height="20px"
              />
            )}
            <Typography color={'black'} style={{ marginLeft: '8px' }}>{userInfo.name}</Typography>
            
            <IconButton
              edge="end"
              color={primaryColor}
              style={{ marginLeft: '16px' }}
              onClick={() => {
                disconnect();
              }}
            >
              <LogoutIcon/>
            </IconButton>
          </Button>
        </Row>
      </Row>
    </Row>
  );
};

export default withTheme(Header);
