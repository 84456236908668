import React from 'react';
import {withTheme} from '@emotion/react';
import LeftPanelStepIndicator from "./LeftPanelStepIndicator";
import {LISTING_STEPS} from "../../../pages/Reseller/SecureListingPage";
import LeftPanel from "../LeftPanel";
import {useSelector} from "react-redux";

const LeftPanelSecure = ({currentStep, setShowOverlay, setStep}) => {
  const nextStepToFill = useSelector(state => state.currentStep);

  return (
    <LeftPanel setShowOverlay={setShowOverlay}>
      <LeftPanelStepIndicator
        onClick={() => setStep(LISTING_STEPS.selectBrand)}
        selected={LISTING_STEPS.selectBrand - currentStep}
        state={LISTING_STEPS.selectBrand - nextStepToFill}
        title={'Select the brand'}
        description={'Select the brand linked to the product you want to list'}/>
      <LeftPanelStepIndicator
        onClick={() => setStep(LISTING_STEPS.selectProduct)}
        selected={LISTING_STEPS.selectProduct - currentStep}
        state={LISTING_STEPS.selectProduct - nextStepToFill}
        title={'Select the product'}
        description={'Select the product you want to list'}/>
      <LeftPanelStepIndicator
        onClick={() => setStep(LISTING_STEPS.provideUrl)}
        selected={LISTING_STEPS.provideUrl - currentStep}
        state={LISTING_STEPS.provideUrl - nextStepToFill}
        title={'Provide the URL'}
        description={'Provide the URL of the product page'}/>
      <LeftPanelStepIndicator
        onClick={() => setStep(LISTING_STEPS.result)}
        selected={LISTING_STEPS.result - currentStep}
        state={LISTING_STEPS.result - nextStepToFill}
        title={'Download the images'}
        description={'Download the watermarked product images'}/>
    </LeftPanel>
  );
};

export default withTheme(LeftPanelSecure);
