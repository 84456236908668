import React, {useEffect, useState} from 'react';
import {withTheme} from '@emotion/react';
import Row from "../../Layout/Row";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {Typography} from "@mui/material";
import Column from "../../Layout/Column";

const getColor = (state) => {
  if (state <= 0)
    return 'primary';
  else
    return 'secondary'
}

const LeftPanelStepIndicator = ({state, selected, title, description, theme, onClick}) => {

  const { palette } = theme;
  const secondaryColor = palette.background.secondary;
  const secondaryTextColor = palette.text.secondary;
  const rowStyle = {marginBottom: '24px', position: 'relative'}

  if (state <= 0) {
    rowStyle['cursor'] = 'pointer';
  } else {
    onClick = () => {};
  }

  return (
    <Row style={rowStyle} onClick={onClick}>
      <CheckCircleOutlineOutlinedIcon color={getColor(state)}/>
      <div style={{marginLeft: '12px'}}/>
      <Column style={{marginTop: '-6px'}}>
        <Typography variant='div' fontWeight='bold'>{title}</Typography>
        <Typography variant='div' color={secondaryTextColor}>{description}</Typography>
      </Column>
      {selected !== 0 && (<div style={{
        height: '103%',
        width: '100%',
        backgroundColor: secondaryColor,
        zIndex: 10,
        position: 'absolute',
        marginTop: '-3px',
        opacity: '65%'
      }}>
      </div>)}
    </Row>
  );
};

export default withTheme(LeftPanelStepIndicator);
