import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import Header from "../../components/Layout/Header";
import Row from "../../components/Layout/Row";
import LoadingPage from '../LoadingPage';
import Column from "../../components/Layout/Column";
import {
  setCurrentStep as _setCurrentStep,
} from "../../store/action";
import { useSnackbar } from "notistack";
import { getResellers } from '../../services/api';
import { useAccount } from 'wagmi';
import LeftPanelBrand from '../../components/leftPanel/brands/LeftPanelBrand';
import { notistackOptions } from '../../utils/notistackUtils';
import RightPanelAddReseller from '../../components/rightPanel/brands/RightPanelAddReseller';
import { useSelector } from 'react-redux';

const AddResellerPage = ({ setShowOverlay }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [resellers, setResellers] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [triggerReRender, setTriggerReRender] = useState(false);
  const [isLeftPanelLoading, setIsLeftPanelLoading] = useState(true);
  const allResellers = useSelector(state => state.resellers);
  const user = useSelector(state => state.user);

  const loadResellers = async () => {
    const _resellers = await getResellers(user.id);
    const enrichedResellers = allResellers.filter(r => _resellers.includes(r.id));
    setIsLeftPanelLoading(false);
    setResellers(enrichedResellers);
  }

  useEffect(() => {
    loadResellers();
  }, []);

  const onResellerAdded = (reseller) => {
    resellers.push(reseller);
    setResellers(resellers);
    setSelectedReseller(reseller);
    setTriggerReRender(!triggerReRender);
  }

  const onResellerRemoved = (reseller) => {
    const list = resellers.filter(r => r.id !== reseller.id);
    setResellers(list);
    if (list.length) {
      setSelectedReseller(list[0]);
    } else {
      setSelectedReseller(null);
    } 
    setTriggerReRender(!triggerReRender);
  }

  if (isLeftPanelLoading) {
    return <LoadingPage/>
  }

  return (
    <Row>
      <LeftPanelBrand
        isLeftPanelLoading={isLeftPanelLoading}
        items={resellers}
        setShowOverlay={setShowOverlay}
        onItemSelected={setSelectedReseller}
        onAddButtonClick={() => {
          if (selectedReseller)
            setSelectedReseller(null);
          else 
            enqueueSnackbar('You are already adding a reseller', notistackOptions.WARNING);
        }}
        type={"reseller"}
      />
      <Column>
        <Header />
        <Column height={'100%'} width={'70vw'} alignItems='center' justifyContent='center'>
          <RightPanelAddReseller selectedReseller={selectedReseller} onResellerAdded={onResellerAdded} authorizedResellers={resellers} onResellerRemoved={onResellerRemoved}/>
        </Column>
      </Column>
    </Row>
  );
};

export default withTheme(AddResellerPage);
