import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
  palette: {
    primary: {
      main: '#005B98',
    },
    secondary: {
      main: '#999797',
    },
    error: {
      main: '#f52733',
    },
    warning: {
      main: '#ba831e',
      background: '#fdce75'
    },
    info: {
      main: '#0590ed',
      background: '#39a5ed'
    },
    text: {
      primary: '#000',
      secondary: '#6F6F6F',
      disabled: '#CCCCCC',
    },
    button: {
      main: '#fff',
    },
    background: {
      default: '#FFF',
      primary: '#000',
      secondary: '#F9FBFC',
      highlight: '#FFF',
      info: '#F5F5F5',
    },
    action: {
      disabledBackground: '#9F9F9F',
      disabled: '#fff',
    },
    link: {
      main: '#0076A9',
    },
    leftPanelCategory: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\',\n' +
      '    \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    h1: {
      fontSize: '30px',
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '22px',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    h6: {
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    div: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'justify'
    },
    button: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: "#333"
    },
    cardTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    cardDescription: {
      fontSize: '12px',
      fontWeight: 'normal',
      textAlign: 'justify'
    },
    overlayTitle: {
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
      color: 'black'
    },
    overlayDescription: {
      fontSize: '14px',
      fontWeight: 'normal',
      textAlign: 'justify',
      color: 'black'
    },
    header: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: 'black'
    },
    headerSelected: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black'
    }
  },
});

export default theme;
