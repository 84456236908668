import React, {useState, useEffect} from 'react';
import Column from "../../../Layout/Column";
import { Typography, useTheme, Button } from "@mui/material";
import Row from "../../../Layout/Row";
import { authorizeProductForResellers, deleteReseller, getProducts } from '../../../../services/api';
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { notistackOptions } from "../../../../utils/notistackUtils";

const SelectableProduct = ({ product, setSelected, selected }) => {

  const handleClick = () => {
    setSelected(product.id);
  };

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        margin: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '20%',
        aspectRatio: 1
      }}
      onClick={handleClick}
    >
      <img
        src={product.photos[0]}
        alt={product.name}
        style={{
          width: '100%',
          height: 'auto',
          opacity: selected ? '1' : '0.5',
          borderRadius: '5px',
        }}
      />
      <input
        type="checkbox"
        style={{
          position: 'absolute',
          bottom: '5px',
          right: '5px',
          opacity: '0.5',
        }}
        checked={selected}
        readOnly
      />
    </div>
  );
};

export const RightPanelAuthorizations = ({ reseller, onResellerRemoved }) => {

  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const user = useSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const red = theme.palette.error.main;
  const [selectedProducts, setSelectedProducts] = useState([]);

  const loadProducts = async () => {
    if (!user)
      return;
    let res = await getProducts(user.id);
    let _selectedProducts = [];
    res = res.map(i => {
      let r = [];
      r = i.customFields.resellers;

      if (r.includes(reseller.id)) {
        _selectedProducts.push(i.id)
      }

      return {
        ...i,
        customFields: {
          ...i.customFields,
          resellers: r
        }
      }
    });
    setSelectedProducts(_selectedProducts);
    setProducts(res);
  }

  useEffect(() => {
    loadProducts();
  }, [user, reseller]);

  const handleImageClick = (productId) => {
    const isSelected = selectedProducts.includes(productId);
    if (isSelected) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const renderImages = () => {
    const rows = [];
    for (let i = 0; i < products.length; i += 4) {
      const row = (
        <Row key={`row-${i}`} alignItems='center' justifyContent='center'>
          {i < products.length && <SelectableProduct product={products[i]} selected={selectedProducts.includes(products[i].id)} setSelected={handleImageClick} />}
          {i + 1 < products.length && <SelectableProduct product={products[i + 1]} selected={selectedProducts.includes(products[i + 1].id)} setSelected={handleImageClick} />}
          {i + 2 < products.length && <SelectableProduct product={products[i + 2]} selected={selectedProducts.includes(products[i + 2].id)} setSelected={handleImageClick} />}
          {i + 3 < products.length && <SelectableProduct product={products[i + 3]} selected={selectedProducts.includes(products[i + 3].id)} setSelected={handleImageClick} />}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  const _deleteReseller = async () => {
    const promises = [];
    for (let i = 0; i < products.length; i++) {
      if (selectedProducts.includes(products[i].id)) {
        promises.push(authorizeProductForResellers(products[i].customFields.resellers.filter(k => k !== reseller.id), products[i].id));
      }
    }
    await Promise.all(promises);
    const code = await deleteReseller(user.id, reseller.id);
    if (code !== 204)
      enqueueSnackbar('An error occurred while removing the reseller', notistackOptions.ERROR);
    else
      enqueueSnackbar('Reseller successfully removed', notistackOptions.SUCCESS);
    onResellerRemoved(reseller);
  }

  const onAuthorizeProductsClick = async () => {
    const promises = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      if (selectedProducts.includes(product.id)) {
        if (!product.customFields.resellers.includes(reseller.id))
          promises.push(authorizeProductForResellers([...product.customFields.resellers, reseller.id], product.id));
      }
      else {
        promises.push(authorizeProductForResellers(product.customFields.resellers.filter(k => k !== reseller.id), product.id));
      }
    }
    await Promise.all(promises);
    enqueueSnackbar('Authorizations updated', notistackOptions.SUCCESS);
  }

  return (
    <Column width='100%' height='100%' justifyContent='center' alignItems='center'>
      <Typography variant='h1' mb='36px'>{reseller.name}</Typography>
      <Column>
        {renderImages()}
      </Column>
      <Button onClick={onAuthorizeProductsClick} variant="contained" component="label" disableElevation style={{ width: '40%', marginTop: '18px' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Update authorizations"}
        </Typography>
      </Button>
      <Button onClick={_deleteReseller} variant="contained" component="label" disableElevation
        style={{ width: '40%', marginTop: '36px', backgroundColor: red }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Deauthorize reseller"}
        </Typography>
      </Button>
    </Column>
  );
}