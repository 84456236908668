import React, { useState } from 'react';
import { withTheme } from '@emotion/react';
import { Typography, Button, Select, InputLabel, FormControl, MenuItem, CircularProgress } from "@mui/material";
import Column from "../../Layout/Column";
import { useSnackbar } from "notistack";
import { notistackOptions } from "../../../utils/notistackUtils";
import { addReseller } from '../../../services/api';
import { useSelector } from "react-redux";
import RightPanelResellerView from './RightPanelResellerView';

const RightPanelAddReseller = ({ onResellerAdded, onResellerRemoved, selectedReseller, authorizedResellers }) => {
  const [reseller, setReseller] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const resellers = useSelector(state => state.resellers);
  const user = useSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();

  const resellersNotAdded = resellers.filter(r => !(authorizedResellers.map(i => i.id).includes(r.id)));

  const onAddResellerClick = async () => {

    if (!reseller) {
      enqueueSnackbar('Please select a reseller', notistackOptions.ERROR);
      return;
    }

    setIsLoading(true);
    const res = await addReseller(user.id, reseller);
    if (res.includes(reseller)) {
      setIsLoading(false);
      onResellerAdded(resellers.find(i => i.id === reseller));
    } else {
      enqueueSnackbar('An error occurred while adding the reseller', notistackOptions.ERROR);
    }
  }

  if (selectedReseller)
    return <RightPanelResellerView reseller={selectedReseller} onResellerRemoved={onResellerRemoved} />

  if (isLoading)
    return (<Column width='100%' height='100%' justifyContent='center' alignItems='center'>
      <CircularProgress />
    </Column>)

  return (
    <Column width='50%' alignItems='center' style={{ maxWidth: '500px' }}>
      <Typography variant='h1'>Add a reseller</Typography>
      <FormControl fullWidth style={{ marginTop: '24px' }}>
        <InputLabel id="demo-simple-select-label">Reseller</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reseller}
          label="reseller"
          onChange={(e) => setReseller(e.target.value)}
        >
          {Object.keys(resellersNotAdded).map((k) => <MenuItem value={resellersNotAdded[k].id}>{resellersNotAdded[k].name}</MenuItem>)}
        </Select>
      </FormControl>
      <Button onClick={onAddResellerClick} variant="contained" component="label" disableElevation style={{ width: '100%', marginTop: '18px' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>
          {"Authorize reseller"}
        </Typography>
      </Button>
    </Column>
  );
};

export default withTheme(RightPanelAddReseller);
