import Column from "../../../Layout/Column";
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Title,
    Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { Paper, Typography } from "@mui/material";
import Row from "../../../Layout/Row";


const AxisLabel = (({ classes, ...restProps }) => (
    <ArgumentAxis.Label
        {...restProps}
        className={classes.labelText}
        angle={-45} // Rotate the labels to prevent overlap
    />
));

const Root = props => (
    <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
    <Legend.Label {...props} sx={{ whiteSpace: 'nowrap', fontSize: '0.3em' }} />
);

const countTopUrls = (list, topCount = 4) => {
    const urlMap = {};
    const data = [];

    list.forEach(i => {
        const item = i.context;
        if (urlMap[item.url]) {
            urlMap[item.url].count++;
            if (item.copy) urlMap[item.url].copyCount++;
            if (!item.authorized && !item.copy) urlMap[item.url].notAuthorizedCount++;
        } else {
            urlMap[item.url] = {
                count: 1,
                copyCount: item.copy ? 1 : 0,
                notAuthorizedCount: (!item.copy && !item.authorized) ? 1 : 0,
            };
        }
    });

    const sortedUrls = Object.entries(urlMap).sort((a, b) => b[1].count - a[1].count);
    let otherCount = 0;
    let otherCopyCount = 0;
    let otherNotAuthorizedCount = 0;

    sortedUrls.forEach(([url, { count, copyCount, notAuthorizedCount }], index) => {
        if (index < topCount) {
            data.push({ url: url, count: count - copyCount - notAuthorizedCount, copyCount, notAuthorizedCount });
        } else {
            otherCount += count;
            otherCopyCount += copyCount;
            otherNotAuthorizedCount += notAuthorizedCount;
        }
    });

    if (otherCount) {
        data.push({ url: "other", totalCount: otherCount, copyCount: otherCopyCount, notAuthorizedCount: otherNotAuthorizedCount });
    }

    return data;
};

export const RightPanelAnalytics = ({ analytics }) => {

    if (analytics.message === "Error: NFT not found") {
        return (
            <Row width='100%' height='100%' alignItems='center' justifyContent='center'>
                <Typography>Analytics isn't enabled for this product</Typography>
            </Row>
        );
    }

    const { scans } = analytics;

    const data = countTopUrls(scans);

    if (!scans.length) {
        return (
            <Row width='100%' height='100%' alignItems='center' justifyContent='center'>
                <Typography>We didn't record any scans of your product's images</Typography>
            </Row>
        );
    }

    return (
        <Row alignItems='center' justifyContent='center'>
            <Column width='70%'>
                <Paper>
                    <Chart
                        data={data}
                    >
                        <ArgumentAxis />
                        <ValueAxis
                            max={2400}
                        />
                        <BarSeries
                            name="Authorized"
                            valueField="count"
                            argumentField="url"
                        />
                        <BarSeries
                            name="Copies"
                            valueField="copyCount"
                            argumentField="url"
                        />
                        <BarSeries
                            name="Not authorized"
                            valueField="notAuthorizedCount"
                            argumentField="url"
                        />
                        <Animation />
                        <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                        <Title text="Scans" />
                        <Stack
                            stacks={[
                                { series: ['Authorized', 'Copies', 'Not authorized'] },
                            ]}
                        />
                    </Chart>
                </Paper>
            </Column>
        </Row>
    )
}