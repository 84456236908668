export const getFileFromUrl = async (url) => {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return new File([arrayBuffer], 'image.jpg', { type: 'image/jpeg' });
}

export const getQueryParam = name => new URLSearchParams(window.location.search).get(name);

export const timer = ms => new Promise(res => setTimeout(res, ms));

export const openWindow = (url) => {
  window.open(url, "_blank");
}

/**
 * Save localStorage item.
 *
 * @param {string} key - Key name.
 * @param {object} data - Date to store.
 */
export const saveToLocalStorage = (key, data) => localStorage.setItem(key, JSON.stringify(data));

export const loadAllLocalStorage = () => {
  const data = {};
  data['image'] = load('image')
  data['imageName'] = load('imageName')
  data['txHash'] = load('txHash')
  data['signature'] = load('signature')
  data['tokenInformation'] = load('tokenInformation')
  data['NFTMetadata'] = load('NFTMetadata')
  data['mintingMethod'] = parseInt(load('mintingMethod'))
  data['currentStep'] = parseInt(load('currentStep'))
  return data;
}

/**
 * remove a localStorage item.
 *
 * @param {string} key - Key name.
 */
export const removeFromLocalStorage = (key) => localStorage.removeItem(key);

export const clearLocalStorage = () => {
  removeFromLocalStorage('image');
  removeFromLocalStorage('txHash');
  removeFromLocalStorage('signature');
  removeFromLocalStorage('tokenInformation');
  removeFromLocalStorage('NFTMetadata');
  removeFromLocalStorage('mintingMethod');
  removeFromLocalStorage('currentStep');
}

/**
 * Load localStorage item.
 *
 * @param {string} key - Key name.
 * @returns {object} Date retrieved.
 */
export const load = key => localStorage.getItem(key);

export const base64toFile = (base64) => {
  const binary = atob(base64.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  const file = new File([new Uint8Array(array)], "image.jpg", { type: "image/jpeg" });

  return file;
}

export const isUrl = (str) => {
  const pattern = /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(?:\/[\w-./?%&=]*)?$/i;
  return pattern.test(str);
}

export const getDomain = (url) => {
  const parsedUrl = new URL(url);
  const domain = `${parsedUrl.protocol}//${parsedUrl.host}`;
  return domain;
}