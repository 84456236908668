import {saveToLocalStorage} from "../utils/utils";
import store from "./store";

/**
 *
 * @param {{number, number}} value - {width, height}
 * @returns {{type: string, value: *}}
 */
export const setScreenSize = (value) => ({
  type: 'SET_SCREEN_SIZE',
  value,
});

/**
 *
 * @param {boolean} value - true if it is on mobile - false otherwise
 * @returns {{type: string, value: *}}
 */
export const setIsMobile = (value) => ({
  type: 'SET_IS_MOBILE',
  value,
});

export const setSelectedBrand = (value) => {
  return {
    type: 'SET_SELECTED_BRAND',
    value,
  };
};

export const setSelectedProduct = (value) => {
  return {
    type: 'SET_SELECTED_PRODUCT',
    value,
  };
};

export const setUrl = (value) => {
  return {
    type: 'SET_URL',
    value,
  };
};

export const setImageName = (value) => {
  saveToLocalStorage('imageName', value);
  return {
    type: 'SET_IMAGE_NAME',
    value,
  }
};

/**
 *
 * @param {{tokenId: string, contractAddress: string, chainId: number}} value
 * @returns {{type: string, value}}
 */
export const setTokenInformation = (value) => {
  saveToLocalStorage('tokenInformation', value);
  return {
    type: 'SET_TOKEN_INFORMATION',
    value,
  };
};

export const setNFTMetadata = (value) => {
  saveToLocalStorage('NFTMetadata', value);
  return {
    type: 'SET_NFT_METADATA',
    value,
  }
};

export const setSelectedNFT = (value) => ({
  type: 'SET_SELECTED_NFT',
  value,
});

export const setRedirections = (value) => {
  saveToLocalStorage('redirections', value);
  return {
    type: 'SET_REDIRECTIONS',
    value,
  }
};

export const setResellers = (value) => {
  return {
    type: 'SET_RESELLERS',
    value,
  }
};

export const setBrands = (value) => {
  return {
    type: 'SET_BRANDS',
    value,
  }
};

export const setUser = (value) => {
  saveToLocalStorage('user', value);
  return {
    type: 'SET_USER',
    value,
  }
};

export const setCurrentStep = (value) => {
  const {currentStep} = store.getState();
  if (value < currentStep)
    value = currentStep;

  saveToLocalStorage('currentStep', value);
  return {
    type: 'SET_CURRENT_STEP',
    value,
  };
};
