import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import Header from "../../components/Layout/Header";
import Row from "../../components/Layout/Row";
import LeftPanel from "../../components/leftPanel/secureListing/LeftPanelSecure";
import Column from "../../components/Layout/Column";
import RightPanelSelectBrand from "../../components/rightPanel/secureListingPage/RightPanelSelectBrand";
import {
  setCurrentStep as _setCurrentStep,
} from "../../store/action";
import { OnBrowserBackHistoryClickOverlay } from "../../components/Overlays";
import RightPanelSelectProduct from '../../components/rightPanel/secureListingPage/RightPanelSelectProduct';
import RightPanelProvideURL from '../../components/rightPanel/secureListingPage/RightPanelProvideURL';
import RightPanelDownload from '../../components/rightPanel/secureListingPage/RightPanelDownload';
import store from '../../store/store';

export const LISTING_STEPS = {
  selectBrand: 1,
  selectProduct: 2,
  provideUrl: 3,
  result: 4
}

const SecureListingPage = ({ setShowOverlay }) => {
  const [currentStep, setCurrentStep] = useState(LISTING_STEPS.selectBrand);
  const [goBackOverlay, showGoBackOverlay] = useState(false);

  const onBackButtonEvent = (e) => {
    if (goBackOverlay) {
      window.history.back();
    } else {
      showGoBackOverlay(true);
      e.preventDefault();
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  return (
    <Row>
      <LeftPanel currentStep={currentStep} setShowOverlay={setShowOverlay} setStep={setCurrentStep} />
      <Column>
        <Header showNavigationButtons={false} />
        <Column height={'100%'} width={'70vw'} alignItems='center' justifyContent='center'>
          <OnBrowserBackHistoryClickOverlay show={goBackOverlay} onCancel={() => {
            showGoBackOverlay(false);
          }} onGoBack={() => {
            window.history.back();
          }} />
          {currentStep === LISTING_STEPS.selectBrand &&
            <RightPanelSelectBrand
              setNextStep={() => {
                setCurrentStep(LISTING_STEPS.selectProduct);
                store.dispatch(_setCurrentStep(LISTING_STEPS.selectProduct));
              }}
            />
          }
          {currentStep === LISTING_STEPS.selectProduct &&
            <RightPanelSelectProduct
              setNextStep={() => {
                setCurrentStep(LISTING_STEPS.provideUrl);
                store.dispatch(_setCurrentStep(LISTING_STEPS.provideUrl));
              }}
            />
          }
          {currentStep === LISTING_STEPS.provideUrl &&
            <RightPanelProvideURL
              setNextStep={() => {
                setCurrentStep(LISTING_STEPS.result);
                store.dispatch(_setCurrentStep(LISTING_STEPS.result));
              }}
            />
          }
          {currentStep === LISTING_STEPS.result &&
            <RightPanelDownload onError={() => {
              setCurrentStep(LISTING_STEPS.provideUrl);
              store.dispatch(_setCurrentStep(LISTING_STEPS.provideUrl));
            }}/>
          }
        </Column>
      </Column>
    </Row>
  );
};

export default withTheme(SecureListingPage);
