import React, { useEffect, useState } from 'react';
import { withTheme } from '@emotion/react';
import { CircularProgress, Tab, Tabs } from "@mui/material";
import Column from "../../Layout/Column";
import { getProductData, getSecuredUrls } from '../../../services/api';
import { RightPanelAnalytics } from './productView/RightPanelAnalytics';
import { RightPanelGuardian } from './productView/RightPanelGuardian';
import { RightPanelSecuredURLs } from './productView/RightPanelSecuredURLs';

const RightPanelProductView = ({ productId }) => {
    const [tab, setTab] = useState(0);
    const [analyticsLoading, setAnalyticsLoading] = useState(true);
    const [securedURLsLoading, setSecuredURLsLoading] = useState(true);
    const [analytics, setAnalytics] = useState(null);
    const [urls, setUrls] = useState(null);

    const loadAnalytics = async () => {
        setAnalyticsLoading(true);
        const res = await getProductData(productId);
        setAnalytics(res);
        setAnalyticsLoading(false);
    }

    const loadSecuredURLs = async () => {
        setSecuredURLsLoading(true);
        setUrls(await getSecuredUrls(productId));
        setSecuredURLsLoading(false);
    }

    useEffect(() => {
        loadAnalytics();
        loadSecuredURLs();
    }, [productId]);

    const onTabChange = (event, newTab) => {
        setTab(newTab);
    }

    if (analyticsLoading)
        return <Column alignItems='center' justifyContent='center'>
            <CircularProgress />
        </Column>

    return (
        <Column height='100%' width='100%'>
            <div style={{ marginTop: '30px' }} />
            <Tabs value={tab} onChange={onTabChange} centered>
                <Tab label="Analytics" />
                <Tab label="Guardian" />
                <Tab label="Secured URLs" />
            </Tabs>
            <div style={{ marginTop: '30px' }} />
            {tab === 0 && !analyticsLoading && <RightPanelAnalytics analytics={analytics} />}
            {tab === 1 && !analyticsLoading && <RightPanelGuardian analytics={analytics} />}
            {tab === 2 && !securedURLsLoading && <RightPanelSecuredURLs urls={urls} />}
        </Column>
    )

};

export default withTheme(RightPanelProductView);
