import React, {useState} from 'react';
import {withTheme} from '@emotion/react';
import Column from "./Layout/Column";
import {Backdrop, Button, Typography, useTheme} from "@mui/material";
import protect_icon from '../assets/protect_nft.png';
import CloseIcon from '@mui/icons-material/Close';
import Row from "./Layout/Row";

const PAGES = {
  howToProtect: {
    id: 1,
    title: 'Protection',
    description: 'In order to secure the association between an image and its corresponding NFT, we embed certain information into the image using an unbreakable pattern. This information includes the contract address, NFT token id, and the target blockchain on which the NFT is minted. By embedding this information, it becomes impossible to create counterfeit NFTs by using a copy of the image.'
  },
  extension: {
    id: 2,
    title: 'NFT interaction',
    description: 'Our web extension provides a secure way to verify the authenticity of NFTs on various marketplaces. By installing the extension, you can be confident that the NFTs you purchase or view are legitimate. By using it, you can have peace of mind knowing that your NFT transactions are secure.'
  },
}

const StepIndicator = ({page}) => {

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Row width='100%' alignItems='center' justifyContent='center'>
      <div style={{
        height: '8px',
        width: '8px',
        borderRadius: '100%',
        backgroundColor: page.id === 1? primaryColor : '#CCC'
      }}/>
      <div style={{
        marginLeft: '12px',
        height: '8px',
        width: '8px',
        borderRadius: '100%',
        backgroundColor: page.id === 2? primaryColor : '#CCC'
      }}/>
    </Row>
  )
}

const HelpOverlay = ({show, setShow}) => {

  const [page, setPage] = useState(PAGES.howToProtect);

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const onBack = () => {
    if (page.id === PAGES.howToProtect.id)
      setShow(false);
    else
      setPage(PAGES.howToProtect);
  }

  const onContinue = () => {
    if (page.id === PAGES.howToProtect.id)
      setPage(PAGES.extension);
    else
      setShow(false);
  }

  if (!show)
    return <></>;

  return (
    <Backdrop
      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      open={show}
    >
      <Column
        style={{
          width: '40%',
          maxWidth: '500px',
          opacity: 1,
          zIndex: 1000,
          position: 'relative'
        }}>
        <div
          alt={''}
          style={{
            borderRadius: '18px',
            position: 'absolute',
            background: 'linear-gradient(rgba(21,131,205,1), rgba(250,250,250,1), rgba(250,250,250,1))',
            width: '100%',
            height: '100%',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat'
          }}
        />
        <div style={{position: 'absolute', right: '24px', top: '24px', cursor: 'pointer', zIndex: 1800}}
             onClick={() => setShow(false)}>
          <CloseIcon/>
        </div>
        <Column width='100%' height='100%' alignItems='center' justifyContent='space-between'
                style={{zIndex: 'inherit'}}>
          <img alt={''} src={protect_icon} width='50%' style={{marginBottom: '-30px'}}/>
          <Column sx={{margin: '32px'}}>
            <Typography variant='overlayTitle' sx={{zIndex: 'inherit'}}>{page.title}</Typography>
            <span style={{height: '24px'}}/>
            <Typography variant='overlayDescription' sx={{zIndex: 'inherit'}}>{page.description}</Typography>
            <span style={{height: '24px'}}/>
            <StepIndicator page={page}/>
            <Row style={{marginTop: '24px'}}>
              <Button onClick={onBack} variant="outlined" component="label" disableElevation style={{width: '100%'}}>
                <Typography variant='button' style={{textTransform: 'none', color: primaryColor}}>
                  Back
                </Typography>
              </Button>
              <span style={{width: '24px'}}/>
              <Button onClick={onContinue} variant="contained" component="label" disableElevation
                      style={{width: '100%', height: '45px'}}>
                <Typography variant='button' style={{textTransform: 'none', color: 'white'}}>
                  Continue
                </Typography>
              </Button>
            </Row>
          </Column>
        </Column>
      </Column>
    </Backdrop>
  )
};

export default withTheme(HelpOverlay);
