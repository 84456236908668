import React from 'react';
import {withTheme} from '@emotion/react';
import Digimarc from '../assets/digimarc.png'

const DigimarcLogo = () => {

  return (
    <div>
      <img src={Digimarc} alt='DigimarcLogo' height='45px' width='auto'/>
    </div>
  );
};

export default withTheme(DigimarcLogo);
