import Column from "../../../Layout/Column";
import { Typography, useTheme } from "@mui/material";
import Row from "../../../Layout/Row";
import store from "../../../../store/store";

const getResellerImage = (resellerId) => {
  const { resellers } = store.getState();
  return (<img height='50px' src={resellers.find(r => r.id === resellerId).image} />);
}

const getResellerName = (resellerId) => {
  const { resellers } = store.getState();
  return resellers.find(r => r.id === resellerId).name;
}

const URL = ({ info }) => {
  const theme = useTheme();

  return (
    <Row width='100%' justifyContent='center' alignItems='center'>
      <Row width='70%' justifyContent='space-between' alignItems='center' sx={{
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '6px',
        padding: '18px',
        marginBottom: '24px'
      }}>
        <Row alignItems='center'>
          {getResellerImage(info.resellerId)}
          <Typography ml='24px'>{getResellerName(info.resellerId)}</Typography>
        </Row>
        <Typography><a href={info.url} target='_blank'>{info.url}</a></Typography>
      </Row>
    </Row>
  );
}

export const RightPanelSecuredURLs = ({ urls }) => {

  if (!urls.length) {
    return (
      <Row width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Typography>We didn't find any listings of your product</Typography>
      </Row>
    );
  }

  return (
    <Column width='100%' alignItems='center' justifyContent='center'>
      {urls.map((url, i) => <URL info={url} />)}
    </Column>
  )
}