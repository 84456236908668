const DES_API_TOKEN = 'Basic aWxhdHRlc3RpbmcyOjFRQUpmck56YVZjNDJiWS9UVm1hLy9qWU9LVEpseDNk';
const DES_API_URL = 'https://labs-api.digimarc.net';
const fetch = require('node-fetch');

export const createWatermarkService = async (productId, brandId, resellerId) => {
    const myHeaders = new fetch.Headers();
    myHeaders.append("Authorization", DES_API_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    
  
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startDate = today.toISOString().replace(/T.+/, 'T00:00:00Z');
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(
        {
          "ProjectId": 162867,
          "Name": 'v4ecommerce_' + productId + '_' + brandId + '_' + resellerId + '_' + now,
          "Description": null,
          "PayoffUrl": null,
          "PayoffTitle": "",
          "PayoffType": "StandardPayoff",
          "StartDate": startDate,
          "ProductId": 999,
          "Status": "Active",
          "ServiceType": "Visual",
          "IsHighVolume": false,
          "PurchaseStatus": null,
          "PurchaseDate": null,
          "PayoffSubtitle": null,
          "CanPreview": true,
          "AutoExtend": false,
          "IsExtendable": false,
          "HasCustomThumbnail": true,
          "IsDemo": false,
          "SubscriptionType": "OneYear",
          "IsRecyclingSortation": false,
          "AppliedOffers": {
              "HolyGrail": false
          }
      }
      ),
      redirect: 'follow'
    };
  
    let res
    let json;
    try {
      res = await fetch(`${DES_API_URL}/v2/service?account=3804`, requestOptions);
      console.log(res)
      json = await res.json()
      return ({'ServiceId': json?.Id});
    } catch (error) {
      console.log(res)
      console.log('error',error)
    }
    // const res = await fetch(`${DES_API_URL}/v2/service?account=3804`, requestOptions);
    // const json = await res.json()
  }
  
 export  const getWatermarkServiceInfo = async (ServiceId) => {
    const myHeaders = new fetch.Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", DES_API_TOKEN);
  
    const requestOptions2 = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const res2 = await fetch(`${DES_API_URL}/v2/service/${ServiceId}?account=3804`, requestOptions2);
    const json2 = await res2.json();
  
    return ({'Payload': json2.Payload, 'CpmPath': json2.CpmPath});
  }
  