import React, {useState, useEffect} from 'react';
import {withTheme} from '@emotion/react';
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import Column from "../../Layout/Column";
import store from "../../../store/store";
import {useSelector} from 'react-redux';
import { setSelectedBrand } from '../../../store/action';
import { getResellerAssociatedBrands } from '../../../services/api';
import {useAccount} from 'wagmi';

const RightPanelSelectBrand = ({setNextStep}) => {

  const enrichedBrands = useSelector(state => state.brands);
  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);

  const onButtonClick = () => {
    store.dispatch(setSelectedBrand(brand));
    setNextStep();
  }

  const loadBrands = async () => {
    setIsLoading(true);
    const res = await getResellerAssociatedBrands(user.id);
    const enrichedRes = enrichedBrands.filter(b => res.includes(b.id));
    setBrands(enrichedRes);
    setBrand(enrichedRes[0]?.id)
    setIsLoading(false);
  }

  useEffect(() => {loadBrands()}, [user]);

  if (isLoading)
    return (
      <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
        <CircularProgress/>
      </Column>
    )

  if (!brands.length) {
    return (
      <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Typography variant='h6' mr="50px" ml="50px">You need to be whitelisted by a brand to use the product</Typography>
      </Column>
    )
  }

  return (
    <Column width='50%' alignItems='center' style={{maxWidth: '500px'}}>
      <Typography variant='h1'>Select the brand</Typography>
      <FormControl fullWidth style={{marginTop: '24px'}}>
        <InputLabel id="demo-simple-select-label">Brand</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={brand}
          label="brand"
          onChange={(e) => setBrand(e.target.value)}
        >
          {brands.map((k) => <MenuItem value={k.id}>{k.name}</MenuItem>)}
        </Select>
      </FormControl>
      <Button onClick={onButtonClick} variant="contained" component="label" disableElevation style={{width: '100%', marginTop: '18px'}}>
        <Typography variant='button' style={{textTransform: 'none', color: 'white'}}>
          {"Next"}
        </Typography>
      </Button>
    </Column>
  );
};

export default withTheme(RightPanelSelectBrand);
