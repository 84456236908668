import Column from "../../../Layout/Column";
import {Typography, useTheme} from "@mui/material";
import Row from "../../../Layout/Row";

const Copy = ({info, index}) => {
  const theme = useTheme();

  return (
    <Row width='100%' justifyContent='center' alignItems='center'>
      <Row width='70%' justifyContent='space-between' alignItems='center' sx={{
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '6px',
        padding: '18px',
        marginBottom: '24px'
      }}>
        <Typography sx={{fontWeight: 'bold'}}>Copy n°{index + 1}</Typography>
        <Typography><a href={info.url} target='_blank'>{info.url}</a></Typography>
      </Row>
    </Row>
  );
}

export const RightPanelGuardian = ({analytics}) => {

  if (analytics.message === "Error: NFT not found") {
    return (
      <Row width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Typography>Analytics isn't enabled for this product</Typography>
      </Row>
    );
  }

  const {copies} = analytics;

  if (!copies.length) {
    return (
      <Row width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Typography>We didn't find any unauthorized listings of your product</Typography>
      </Row>
    );
  }

  return (
    <Column width='100%' alignItems='center' justifyContent='center'>
      {copies.map((copy, i) => <Copy info={copy} index={i}/>)}
    </Column>
  )
}