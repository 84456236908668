import React from 'react';
import { withTheme, useTheme } from '@emotion/react';
import LeftPanel from "../LeftPanel";
import { Button, CircularProgress, Typography } from "@mui/material";
import Row from "../../Layout/Row";
import Column from "../../Layout/Column";

const LeftPanelBrand = ({ isLeftPanelLoading, setShowOverlay, items, onItemSelected, onAddButtonClick, type }) => {

  const theme = useTheme();
  const color = theme.palette.leftPanelCategory.main;

  return (
    <LeftPanel bottomComponent={
      <Button variant="contained" disableElevation onClick={onAddButtonClick} style={{ marginBottom: '18px' }}>
        <Typography variant='button' style={{ textTransform: 'none', color: 'white' }}>Add a {type}</Typography>
      </Button>
    } setShowOverlay={setShowOverlay}>
      {isLeftPanelLoading &&
        <Column width='100%' height='100%' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Column>
      }
      {!isLeftPanelLoading &&
        <Column height='100%' justifyContent='space-between'>
          <Column width='100%' alignItems='center'>
            {
              items.length !== 0 && items.map(item =>
                <Row
                  width='95%'
                  mb='24px'
                  sx={{ borderRadius: '8px', backgroundColor: color, padding: '8px', cursor: 'pointer' }}
                  onClick={() => onItemSelected(item)}
                  alignItems='center'>
                  <img src={item.photos? item.photos[0] : item.image} height='60px' />
                  <Typography variant='h6' ml='18px'>{item.name}</Typography>
                </Row>)
            }
            {
              items.length === 0 && (
                <Column width='100%' height='100%' justifyContent='center' alignItems='center'>
                  <Typography>You don't have any {type} yet</Typography>
                </Column>
              )
            }

          </Column>
        </Column>
      }
    </LeftPanel>
  );
};

export default withTheme(LeftPanelBrand);
